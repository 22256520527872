import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import classes from '../pages/Pricing.module.css'
import Layout from '../containers/Layout/Layout'
import {graphql, useStaticQuery} from 'gatsby'
import decor from '../assets/images/blueberries.webp'

const Pricing = () => {
    const pricingInfoQuery = useStaticQuery(graphql`
            query {
                allContentfulPricingInfoPricingInfoTextNode {
                edges {
                    node {
                    internal {
                        content
                    }
                    }
                }
                }
            }
        `);
    
    const pricingInfoText = pricingInfoQuery.allContentfulPricingInfoPricingInfoTextNode.edges.map((edge) => {
        return (edge.node.internal.content)
    });

    return(
        <Layout>
            <div className={classes.PricingContainer}>
                <div className={classes.Pricing}>
                    <div className={classes.HeaderContent}>
                        <h2>Pricing</h2>
                        <img src={decor} alt="Blueberry design decoration" className={classes.HeaderImg}/>
                        <p>Pricing varies based on group size and where you are located. 
                        A portion of total payment is required ahead of time to reserve large orders and private events. 
                        I accept Cash, Check, PayPal, Facebook Pay and Venmo (card coming soon!). 
                        Group minimum is typically 8, but feel free to submit an inquiry for less and I will get back to you on availability. 
                        Fill out the order form and I will be in contact with a quote! 
                        Below is my base pricing without delivery factored in. 
                        If your desired group size is not below, submit an inquiry and I will provide you with a custom quote!</p>
                        <p>All my boards include a variety of high end meats, cheeses, pickled items, spreads, jams, fruits, complimentary items, sweets, crackers and more. Boards can be used as an appetizer or as the main dish - this all depends on how much your group can eat! 
                        If you are requesting a board for a Holiday, there will also be a slight increase for Holiday pricing.</p>
                    </div>
                    <div className={classes.PricingOptions}>
                        <div className={classes.PricingOption}>
                            <h3>Mini Graze Table</h3>
                            <div className={classes.PricingOption1} />
                            <p>Serves 8-10 <br />$210</p>
                        </div>
                        <div className={classes.PricingOption}>
                            <h3>Dinner Party Graze</h3>
                            <div className={classes.PricingOption2} />
                            <p>Serves 10-15 <br />$310</p>
                        </div>
                        <div className={classes.PricingOption}>
                            <h3>Happy Hour Graze</h3>
                            <div className={classes.PricingOption3} />
                            <p>Serves 15-20 <br />$400</p>
                        </div>
                        <div className={classes.PricingOption}>
                            <h3>Full Table Graze</h3>
                            <div className={classes.PricingOption4} />
                            <p>The most standard of my large event tables. <br />A 6ft table graze that feeds 80-100 guests. <br /> $1,500</p>
                        </div>
                        <div className={classes.PricingOption}>
                            <h3>Private Workshops</h3>
                            <div className={classes.PricingOption5} />
                            <p>$85 per person <br /> minimum group size of 6 <br /> A travel fee will be added based on location of event </p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Pricing;